<template>
<div>
    <v-btn icon @click="setTheme()" :color="$vuetify.theme.dark ? 'white' : 'third'">
        <v-icon>{{ $vuetify.theme.isDark == true ? 'fi fi-rr-eclipse-alt' : 'fi fi-rr-moon' }}</v-icon>
    </v-btn>
</div>
</template>

<script>
export default {
    created() {
        const theme = sessionStorage.getItem("dark_theme");
        if (theme) {
            if (theme == "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        }
    },
    methods: {
        setTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
            sessionStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
        },
    }
}
</script>
